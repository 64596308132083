@import "../../assets/scss/variables";

.donation-container {
  height: calc(100vh - 130px) !important;
  .d-container {
    height: 100%;
  }
  .card-height {
    height: 98%;
    margin-bottom: 0px;
  }
  .card-title {
    font-weight: bold;
    font-size: 21px;
    margin-left: 15px;
    margin-top: 10px;
  }
  .card-title-conatiner {
    align-self: center;
  }

  .donate-btn {
    left: 300px;
    background: $fullsoon;
    border-radius: 5px;
    color: #fff;
    border: 0;
    font-weight: bold;
    font-size: 15px;
    padding: 9px 31px;
  }

  .selected-prod-div {
    label {
      &:first-child {
        color: #8b8f94;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
      &:nth-child(2) {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        &:hover {
          color: inherit !important;
        }
      }
    }
  }
  .table-height {
    height: 75% !important;
  }
  .text-purple {
    color: $fullsoon !important;
  }
  .text-good {
    color: #14c25a !important;
  }
  .text-soon {
    color: #ffb11b !important;
  }
  .text-out {
    color: #8b8f94 !important;
  }
  .dropdown-toggle::after {
    margin-left: 0.65em;
  }
}

.review-donation {
  height: calc(100vh - 10px) !important;
  .container {
    height: 100% !important;
  }

  .card-height {
    height: 100%;
  }
  .review-donation-table{
    height: calc(100vh - 260px);
  }

  .donate-btn {
    left: 300px;
    background: $fullsoon;
    border-radius: 5px;
    color: #fff;
    border: 0;
    font-weight: bold;
    font-size: 15px;
    padding: 9px 31px;
  }

  .selected-prod-div {
    label {
      &:first-child {
        color: #8b8f94;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
      &:nth-child(2) {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        &:hover {
          color: inherit !important;
        }
      }
    }
  }
}
