.photo-container {
    .files-list {
      li {
        height: 48px;
        background: #ffffff;
        border: 0.5px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        padding-top: 6px !important;

        img {
          width: 20px;
          height: 20px;
          margin-top: 6px;
          cursor: pointer;
        }

        img.eye {
          width: 15px;
          height: 10px;
        }

        span {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #000000;

          &.size {
            font-size: 12px;
          }
        }
      }
    }
  }

  .dropzone {
    text-align: center;
    padding: 20px;
    color: #bdbdbd;
    margin-bottom: 12px;
    background: rgba(99, 83, 234, 0.1);
    border: 1px dashed #6353ea;
    box-sizing: border-box;
    border-radius: 10px;
    height: 142px;

    p {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      padding-top: 40px;
    }
  }
  