.custom-table {
  .tablescroll {
    height: inherit;
  }
  .tablescroll::-webkit-scrollbar {
    width: 6px;
  }

  .tablescroll::-webkit-scrollbar-track {
    background: #eee;
    .dashboardHotelCode {
      div {
        float: left;
      }
  }
}

  .tablescroll {
    /* height: 500px; */
    overflow-y: auto;
    width: 100%;
    /* max-height: 400px; */
    /* max-height: 250px; */
    overflow: auto;

    &.hide-horizontal-scroll {
      overflow-x: hidden !important;
      transition: all 0.3s ease; /* Smooth transition for hover effects */
    
      .table {
        thead,
        tbody {
          tr {
            th,
            td:first-child {
              position: static !important;
            }
          }
        }
      }
    }    
    

    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      outline: 0;
    }
    .table {
      background-color: #fff;
      white-space: nowrap;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
      .form-check-input {
        cursor: pointer;
        border-radius: 2px;
        width: 15px;
        height: 15px;
        &:focus {
          border-color: rgba(0, 0, 0, 0.25);
          box-shadow: none;
        }
        &:checked {
          background-color: #6353ea;
          border-color: transparent;
        }
      }
      td {
        padding: 0.7rem 0.75rem;
        vertical-align: middle;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        font-size: 1rem;
        color: #333;
        font-weight: 400;
        .input-style {
          width: 100%;
          background: #ffffff;
          border: 0.3px solid #8b8f94;
          box-sizing: border-box;
          text-align: center;
          padding: 4px;
          height: 27px;
        }
        #custom-switch-check {
          cursor: pointer;
          border-radius: 10px;
          width: 39px;
          height: 19px;
          &:focus {
            border-color: rgba(0, 0, 0, 0.25);
            box-shadow: none;
          }
          &:checked {
            background-color: #6353ea;
            border-color: transparent;
          }
        }
      }
      tbody {
        .selected-tr {
          background-color: #cad1ff !important;
        }

        .less_qty-tr {
          background-color: #f8d2d9 !important;
        }

        tr {
          td {
            border: none;
            text-overflow: ellipsis;
            overflow: hidden;
            &:first-child {
              position: sticky;
              left: 0;
              background: transparent;
              z-index: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &:nth-of-type(even) {
            background-color: #f3f4fb;
            td {
              &:first-child {
                background-color: #f3f4fb;
              }
            }
          }
        }
        .selected-tr {
          td {
            &:first-child {
              background-color: #cad1ff !important;
            }
          }
          &:nth-of-type(even) {
            background-color: #cad1ff !important;
            td {
              &:first-child {
                background-color: #cad1ff !important;
              }
            }
          }
        }
        .less_qty-tr {
          td {
            &:first-child {
              background-color: #f8d2d9 !important;
            }
          }
          &:nth-of-type(even) {
            background-color: #f8d2d9 !important;
            td {
              &:first-child {
                background-color: #f8d2d9 !important;
              }
            }
          }
        }
      }
      thead {
        th {
          padding: 1rem 0.75rem;
          position: sticky;
          top: 0;
          z-index: 1;
          width: 120px;
          background-color: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .custome-width {
            width: 80px !important;
          }
          @media screen and (max-width: 1440px) {
            width: 85px;
          }
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 2;
          }
        }
      }

      .table-delete-btn {
        background: transparent;
        border: none;
      }
      .dropdown-menu-custom {
        /* inset: unset !important;
        transform: unset !important; */
        width: initial;
      }
      .dropdown-toggle {
        height: 27px;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 40px;
      background-color: #eeeeee;
    }
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      width: 6px;
      height: 6px;
      border-radius: 40px;
    }
  }
}

.lowStockColor {
  color: #ff8692 !important;
}

.eventList{
  height: 583px;
}

div.col-lg-12.labo-orders-table {
  height: calc(100vh - 290px) !important;
}


.scroll-buttons {
  position: relative; /* Ensure buttons are positioned relative to their container */
  display: flex;
  justify-content: space-between;
}

.scroll-button {
  padding: 8px 15px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border: none;
  background: none;
  border-radius: 50%;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Light background on hover */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.scroll-button:disabled {
  cursor: not-allowed;
  opacity: 0.5; /* Visual indication that the button is disabled */
}

.prev-button {
  position: absolute;
  left: -14px; /* Position it on the left */
  top: 1px; /* Center vertically */
  z-index: 999;
}

.next-button {
  position: absolute;
  right: 0px; /* Position it on the right */
  top: 0px; /* Center vertically */
  z-index: 999;
}

.custome-order-table {
  height: 65vh !important;
}