.restaurant-input-field {
    border-radius: 4px !important;
}

.btn-icon {
    border-radius: 4px !important;
}

.edit-vat-btn{
    border: none !important;
    background: none;
}

.dropdown-toggle-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .selected-item-name {
    font-weight: bold;
    color: #873CFC;
    flex: 1;
    text-align: left;
  }
  
  .vat-edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    border: 1px solid #B1A9F5;
    border-radius: 4px;
    padding: 4px 8px;
  }
  
  .vat-value {
    font-weight: bold;
    margin-right: 5px;
    text-align: right;
    color: #B1A9F5;
  }
  
  .edit-vat-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .selected-checkbox {
    font-weight: 700 !important;
    color: #000000 !important;
  }

  .edit-icon{
    width: 15px;
    height: 15px;
  }

  .add-restaurants-modal-body {
    padding-left: 14px !important;
  }

  .close-icon {
    cursor: pointer !important;
    font-size: 30px !important;
  }