@import "assets/scss/variables";

.my-ingredients {
  height: calc(100% - 165px);

  .sort-container {
    text-align: end;

    img {
      height: 38px;
      cursor: pointer;
    }
  }

  .table-container {
    height: calc(100vh - 375px);
  }
}

.add-btn-container {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  width: max-content;
  display: flex;
  align-items: center;
  order: 1;
  flex-grow: 0;

  .add-btn-icon {
    margin-right: 10px;
  }
}

.search-input {
  height: 38px;
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 15px;

  &:focus-visible {
    outline: none;
  }
}

.allergens-container {
  .allergens-badge {
    background-color: #e0e0e0 !important;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    padding: 9px 27px;
    align-items: center;
    text-align: center;
    color: #8b8f94;
    height: 37px;
    margin-bottom: 13px;
    cursor: pointer;
    margin: 0px 10px 10px 0px;

    // &:not(:first-child) {
    //   margin-left: 10px;
    // }

    &.active {
      background-color: $purple !important;
      color: $white !important;
    }
  }
}

.add-ingredient {
  .modal-header {
    border-bottom: #ffffff;
  }

  .modal-body {
    max-height: 75vh;
    overflow-y: auto;

    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      outline: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 40px;
      background-color: #eeeeee;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      width: 6px;
      height: 6px;
      border-radius: 40px;
    }
  }

  .custom-input {
    background: #ffffff;
    border: 0.5px solid #e0e0e0 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;

    &:focus {
      box-shadow: none;
    }
  }
}

.sort-filter {
  button {
    height: 38px;
  }
}

.customSelect {
  height: 33px !important;
}

.page-item.active .page-link {
  background-color: #6353ea !important;
}

.add-ingredient-backdrop {
  z-index: 1051 !important;
}

.text-high {
  color: #14c25a !important;
}

.text-moderate {
  color: #ffb11b !important;
}

.react-time-input-picker-wrapper {
  height: 28px;
  padding: 0 !important;
  margin: 0 !important;
}

.recurrence-modal-body {
  position: "relative";
  overflow-y: "unset";
  .input {
    height: 38px !important;
  }
  .week-day {
    //background: #6353ea;
    background: transparent;
    border-radius: 25px;
    color: #8b8f94;
    border: none;
    padding: 5px;
    width: 37px;
    height: 37px;
  }
  .week-day.active {
    background: #6353ea;
    color: white;
  }
}

.week-day {
  //background: #6353ea;
  background: transparent;
  border-radius: 25px;
  color: #8b8f94;
  border: none;
  padding: 5px;
  width: 37px;
  height: 37px;
}
.week-day.active {
  background: #6353ea;
  color: white;
}

.add-event-body {
  .date-time {
    width: 20% !important;
  }
  .right-arrow {
    right: 0px;
    bottom: 15px;
  }
}

.recurrence-modal-footer {
  border: 0 !important;
  button {
    border-radius: 5px;
    height: 40px;
  }
  .cancel-btn {
    color: #6353ea !important;
    background-color: transparent !important;
  }
  .confirm-btn {
    width: 220.35px;
  }
}

.recurrence-modal {
  max-width: 900px !important;
}

.rangeSlider .rc-slider-track {
  background: #6353ea;

}
.rangeSlider .rc-slider-handle {
  background: #6353ea;
  border-color: #6353ea;
}
.rangeSlider .rc-slider-handle:focus-visible {
  border-color: inherit;
  box-shadow: none;
}
.rangeSlider .rc-slider-handle:active {
border-color: inherit;
  box-shadow: none;
}
.rangeSlider .rc-slider-handle:hover {
border-color: #6353ea;
}


.rc-slider-handle-dragging {
  border-color:  #6353ea !important;
  box-shadow: none !important;
}
 .rc-tooltip-content .rc-tooltip-inner{
  background-color:#6353ea;
}

.rc-tooltip-placement-top .rc-tooltip-arrow{
  border-top-color:#6353ea !important;
}

.events {
  display: flex;
  .rightcontent {
    margin-top: 120px;
    .card {
      height: 100%;
      background: $fullsoon;
      border-radius: 10px;
      .card-body {
        .fltr-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;
          label {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
          }
          button {
            background: transparent;
            border: 0;
            border-bottom: 1px solid #fff;
            padding: 0px 3px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
        }

        .datepicker-wrapper {
          span {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
          .datepicker-wrapper-img {
            display: flex;
            justify-content: flex-end;
          }
          img {
            cursor: pointer;
          }
          .date-picker {
            background: transparent;
            border: 0;
            color: #fff;
            font-weight: bold;
            width: 100%;
            outline: none;
          }
        }

        .react-datepicker-wrapper {
          width: 60%;
        }
        .hr-separator {
          height: 0.5px;
          margin: 0.5rem 0;
          color: #f3f4fb;
        }
        label {
          cursor: pointer;
          &:hover {
            color: #fff !important;
          }
        }
        color: #fff;
        h1 {
          font-weight: bold;
          font-size: 21px;
          line-height: 29px;
        }
        .action-btns {
          bottom: 20px;
          display: flex;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          button {
            background: #f1f3f4;
            border-radius: 5px;
            width: 221px;
            height: 40px;
            width: 102px;
            height: 40px;
            border: 0;
            font-size: 16px;
            font-weight: bold;
            &.close-btn {
              color: #8b8f94;
            }
            &.apply-btn {
              color: #6353ea;
            }
          }
        }
        .reset-action-btns {
          bottom: 20px;
          display: flex;
          position: absolute;
          left: 50%;
          width: 100%;
          transform: translate(-50%, 0);
          button {
            background: #f1f3f4;
            border-radius: 5px;
            width: 221px;
            height: 40px;
            width: 102px;
            height: 46px;
            border: 0;
            font-size: 16px;
            font-weight: bold;
            &.close-btn2 {
              color: #8b8f94;
              width: 100%;
            }
            &.apply-btn {
              color: #6353ea;
            }
          }
        }
        .hide-filter {
          display: flex;
          justify-content: end;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          margin-top: 5px;
          text-decoration: underline;
          cursor: pointer;
          img {
            margin-left: 5px;
          }
        }
      }
      .checkbox {
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        input {
          display: none; /* Hide the default checkbox */
        }
        span {
          height: 15px;
          width: 15px;
          display: inline-block;
          position: relative;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 2px;
        }
        [type="checkbox"]:checked + span:before {
          content: "\2714";
          position: absolute;
          top: -2px;
          left: 1.5px;
          font-size: 14px;
        }
      }
    }
  }
}