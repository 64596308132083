.forecast-container {
  .for-wraper-50 {
    height: 48%;
  }

  .leftcontent {
    .card {
      .card-body {
        .highcharts-container {
          height: 100% !important;
        }
      }
    }
  }
  .rightcontent {
    .event-icon {
      background-color: #000;
      width: 40px;
      height: 40px;
    }
    .weathercard {
      ul {
        &.list {
          li {
            img {
              width: 32px;
              height: 26px;
            }
          }
        }
      }
    }
  }
  .sales-top-flop {
    .btn {
      height: 30px;
      width: 50px;
      padding: 0px 15px;
      img {
        width: 15px !important;
      }
    }
    .meal-types {
      z-index: 1;
      position: absolute;
      background: #6353ea;
      height: 100%;
      width: 100%;
      color: #fff;
      .action-btns {
        bottom: 20px;
        display: flex;
        position: absolute;
        button {
          background: #f1f3f4;
          border-radius: 5px;
          width: 221px;
          height: 40px;
          width: 102px;
          height: 40px;
          border: 0;
          font-size: 16px;
          font-weight: bold;
          &.close-btn {
            color: #8b8f94;
          }
          &.apply-btn {
            color: #6353ea;
          }
        }
      }
    }

    label {
      cursor: pointer;
      &:hover {
        color: #fff !important;
      }
    }

    .checkbox {
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      input {
        display: none;
      }

      span {
        height: 15px;
        width: 15px;
        display: inline-block;
        position: relative;
        border: 1px solid #fff;
        box-sizing: border-box;
        border-radius: 2px;
      }
      [type="checkbox"]:checked + span:before {
        content: "\2714";
        position: absolute;
        top: -2px;
        left: 1.5px;
        font-size: 14px;
      }
    }
    .hide-filter {
      justify-content: end;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      margin-top: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
    .filters-text {
      margin-top: 7px;
    }
  }

  .fltr-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    label {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
    }
    button {
      background: transparent;
      border: 0;
      border-bottom: 1px solid #fff;
      padding: 0px 3px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #f3f4fb;
    }
  }

  @media screen and (max-height: 725px) {
    .top-flop-container {
      position: absolute;
      height: calc(100% - 100px);
      overflow-y: auto;
      margin-right: 5px;
    }
    .action-btns {
      bottom: 10px !important;

      button {
        font-size: 11px !important;
        height: 35px !important;
      }
    }
    .fltrs-container {
      position: absolute;
      height: calc(100% - 110px);
      overflow-y: auto;
      top: 20px;
    }
  }
}

.add-event-link {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-decoration-line: underline;
  color: #7147f1;
  cursor: pointer;
}

.scroll {
  height: 314px;
  overflow: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #adb5bd;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

//NEW CSS

.leftcontent{
  td {
    height: 60px !important;
  }
}

.dashboard {
  height: calc(100vh - 160px);
  .forecast-card {
    height: 100% !important;
    background-color: #F8F9FD;
    box-shadow: none !important;
  }
  .mt-25 {
    margin-top: 25px;
  }
  .second-card {
    height: 48.5%;
  }

  /*   @media screen and (max-height: "785px") {
    .top-flop {
      .top-flop-item {
        &:nth-last-child(2) {
          display: none !important;
        }
      }
    }
  }
 */
  /* @media screen and (max-height: "830px") {
    .top-flop {
      .top-flop-item {
        &:nth-last-child(1) {
          display: none !important;
        }
      }
    }
  } */
  @media screen and (min-width: "1600px") and (max-height: "830px") {
    height: calc(100vh - 190px);
    .forecast-card {
      height: 47%;
    }
  }
  @media screen and (min-width: "1921px") and (max-height: "970px") {
    .top-flop {
      .top-flop-item {
        &:nth-last-child(1) {
          display: none !important;
        }
      }
    }
  }
  @media screen and (min-width: "1921px") and (max-height: "821px") {
    .top-flop {
      .top-flop-item {
        &:nth-last-child(2) {
          display: none !important;
        }
      }
    }
  }
  @media screen and (max-width: "1599px") and (min-height: "831px") {
    height: calc(100vh - 165px) !important;
    .forecast-card {
      height: 49.5%;
    }
    .mt-25 {
      margin-top: 35px;
    }
    .second-card {
      height: 48% !important;
    }
  }
  @media screen and (min-height: "831px") {
    height: calc(100vh - 185px);
    .forecast-card {
      height: 48%;
    }
    // .mt-25 {
    //   // margin-top: 60px;
    // }
    .second-card {
      height: 48.5%;
    }
  }
}

@media screen and (max-height: 850px) {
  .dashboard {
    height: calc(100vh - 150px);
  }
}

.top-flop {
  .text-muted-msg {
    font-size: 13px;
    top: 25px;
    position: relative;
  }
}

.forecast-values {
   display: flex;
   justify-content: center;
   align-items: center;
}
