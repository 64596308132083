@import "../../assets/scss/variables";

.add-ingredient-backdrop {
  z-index: 1051 !important;
}

.add-ingredient {
  .modal-header {
    border-bottom: #ffffff;
  }

  .modal-body {
    max-height: 75vh;
    overflow-y: auto;

    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      outline: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 40px;
      background-color: #eeeeee;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      width: 6px;
      height: 6px;
      border-radius: 40px;
    }
  }

  .custom-input {
    background: #ffffff;
    border: 0.5px solid #e0e0e0 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;

    &:focus {
      box-shadow: none;
    }
  }
}

.inputField {
  height: 53px;
  border-radius: 10px !important;
  color: #6353EA !important;
  background-color: #efedfd !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  // opacity: 10% !important;
  // z-index: -100000000;
}

.place-order-btn {
  width: fit-content;
  height: 40px;
  left: 300px;
  background: $fullsoon;
  border-radius: 5px;
  color: #fff;
  border: 0;
  font-weight: bold;
  font-size: 15px;
  padding: 9px 31px;
}

.add-restaurant-btn-admin {
  background-color: #efedfd !important;
  color: #6353EA !important;
}

.add-btn-icon-btn {
  filter: invert(1);
  margin-right: 10px;
  /* or to blue */
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(205deg)
}

.inputField::placeholder {
  color: #6353EA !important;
  opacity: 60% !important;
}

.dashboard.ereputation-container {
  .leftcontent {
    padding-right: 0 !important;
  }
  .dcards {
    .card {
      .iconholder {
        background: #fff;
        border-radius: 10px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
      }
      .inlineicons {
        display: flex;
        li {
          &:first-child {
            left: 0;
          }
          &:nth-child(2) {
            position: relative;
            left: -12px;
          }
          &:nth-child(3) {
            position: relative;
            left: -24px;
          }
          img {
          }
        }
      }
      h4 {
        font-weight: bold;
        font-size: 20px;
        color: #1b1b1b;
        margin-bottom: 6px;
      }
      span {
        color: #8b8f94;
      }
      &.cardpurple {
        .iconholder {
          background: #f3f4fb;
        }
        .iconholder-green {
          background: #dbffe9;
        }
        .iconholder-red {
          background: #fae6e9;
        }
      }
      .card-body {
        padding: 24px;
        min-height: 110px;
        .d-flex {
        }
      }
    }
  }
  .ratingscore {
    padding: 0 60px 20px 40px;
    .progressitem {
      margin-bottom: 10px;
      p {
        margin-bottom: 6px;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .progress {
      background: #f3f4fb;
    }
    .progress-bar {
      background-color: #f8cd61;
    }
  }
  .userlist {
    height: inherit;
    overflow-y: auto;
    li {
      padding: 20px 30px 20px 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid #eee;
      h3 {
        font-size: 14px;
        font-weight: 700;
      }
      h4 {
        font-size: 14px;
        font-weight: 600;
      }
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
      .left-side {
        width: 100px;
      }
    }
  }
  .ereputation-main {
    height: calc(100vh - 290px);
    .leftcontent-ereputation {
      padding-left: 0;
    }
  }
  .slimscroll {
    &::-webkit-scrollbar-track {
      border-radius: 50px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: #e0e0e0;
    }
  }
  .pie-container {
    height: calc(100vh - 500px);
  }
  .user-avatar {
    height: 40px;
    width: 40px;
  }

  @media screen and (max-height: 830px) {
    .ratingscore {
      .progressitem:nth-last-child(-n + 2) {
        display: none;
      }
    }
    .reviews-container {
      height: calc(100vh - 293px);
    }
    .userlist {
      height: calc(100vh - 400px);
    }
    .ereputation-main {
      height: calc(100vh - 285px);
    }
    .pie-container {
      height: calc(100vh - 485px);
    }
  }

  @media screen and (max-height: 850px) {
    .dcards .card .card-body {
      padding: 15px;
      min-height: 90px;
    }
  }

  @media screen and (max-width: 1599px) and (min-height: 831px) and (max-height: 1000px) {
    .reviews-container {
      height: calc(100vh - 293px);
    }
    .userlist {
      height: calc(100vh - 400px);
    }
    .ereputation-main {
      height: calc(100vh - 285px);
    }
    .pie-container {
      height: calc(100vh - 565px);
    }
  }

  @media screen and (min-width: 1600px) {
    .userlist {
      height: calc(100vh - 465px);
    }
    .ereputation-main {
      height: calc(100vh - 325px);
    }
    .pie-container {
      height: calc(100vh - 625px);
    }
  }

  @media screen and (min-width: 2000px) {
    .userlist {
      /* height: calc(100vh - 465px); */
      max-height: 860px;
    }
    .ereputation-main {
      height: calc(100vh - 325px);
      max-height: 1000px !important;
    }
    .pie-container {
      //height: 600px;
      max-height: 600px;
    }
  }

  @media screen and (min-width: 1600px) and (max-height: 830px) {
    h1,
    .h1 {
      font-size: 2rem !important;
    }
    .userheader p {
      font-size: 14px;
      margin-bottom: 0;
    }
    .wrappers .leftmenu ul li a {
      padding: 10px 17px;
    }
    h2,
    .h2 {
      font-size: 16px;
    }
    .card .card-header {
      padding: 10px 20px 0;
    }
    .card .card-body {
      padding: 10px 10px;
    }
    .btn {
      padding: 5px 15px;
    }
    .card {
      margin-bottom: 12px;
    }
    .reviews-container {
      height: calc(100vh - 315px) !important;
    }
    .pie-container {
      height: calc(100vh - 505px) !important;
    }
    .userlist {
      height: calc(100vh - 430px);
    }
    .ereputation-main {
      height: calc(100vh - 305px);
    }
  }
}


.inputWithButton {
  position: relative;
  height: 100px;
  width : 100%;
  display: flex;
  align-items: center;
}

.inputWithButton input{
    width: 100%;
    height: 55%;
    padding-right: 60px;
}

.inputWithButton button {
  position: absolute;
  right: 10px;
  // top : 10px;
}

.copy-btn {
  background-color: white !important;
  color: #6353EA !important;
  border: none !important;
  font-weight: 700 !important;
}

.active-btn {
  background-color: #6353EA !important;
  color: white !important;
}
