.DemoWrapper {
    .price-container {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 30px;
        .card {
            width: 140px;
            height: 120px;
            background-color: white;
            border: 1px solid lightgray;
            border-radius: 10px;
            padding: 25px;
            &.active {
                border: 2px solid blue; 
                color: black !important;
                background-color: white !important;
                .stripe-icon {
                    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(-152deg) brightness(118%) contrast(119%);
                }
              }
            .stripe-icon {
                width: 35%;
            }
            .card-text {
                font-size: 18px;
                padding-top: 5px;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}