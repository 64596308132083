@import "../../assets/scss/variables";

@media screen and (max-width: 1599px) and (min-height: 831px) {
  .dashboard.market-view-container {
    height: calc(100vh - 170px) !important;
  }
}

.market-view-container {
  display: unset !important;

  .divider {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;
    .navbtns {
      width: 300px;
      li {
        border-bottom: none;
      }
    }
  }
  .leftcontent-market-view {
    .card-navbtns {
      /* border-bottom: 1px solid #e0e0e0; */
      .navbtns {
        margin-bottom: -1px;
      }
    }
  }
  .rightcontent {
    .card {
      height: 100%;
      background: $fullsoon;
      border-radius: 10px;
      .card-body {
        .fltr-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;
          label {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
          }
          button {
            background: transparent;
            border: 0;
            color: #fff;
            border-bottom: 1px solid #fff;
            padding: 0px 3px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
        }

        .datepicker-wrapper {
          span {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
          .datepicker-wrapper-img {
            display: flex;
            justify-content: flex-end;
          }
          img {
            cursor: pointer;
          }
          .date-picker {
            background: transparent;
            border: 0;
            color: #fff;
            font-weight: bold;
            width: 100%;
            outline: none;
          }
        }

        .react-datepicker-wrapper {
          width: 60%;
        }
        .hr-separator {
          height: 0.5px;
          margin: 0.5rem 0;
          color: #f3f4fb;
        }
        label {
          cursor: pointer;
          &:hover {
            color: #fff !important;
          }
        }
        color: #fff;
        h1 {
          font-weight: bold;
          font-size: 21px;
          line-height: 29px;
        }
        .action-btns {
          bottom: 20px;
          display: flex;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          button {
            background: #f1f3f4;
            border-radius: 5px;
            width: 221px;
            height: 40px;
            width: 102px;
            height: 40px;
            border: 0;
            font-size: 16px;
            font-weight: bold;
            &.close-btn {
              color: #8b8f94;
            }
            &.apply-btn {
              color: #6353ea;
            }
          }
        }
        .hide-filter {
          display: flex;
          justify-content: end;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          margin-top: 5px;
          text-decoration: underline;
          cursor: pointer;
          img{
            margin-left: 5px;
          }
        }
      }
      .checkbox {
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        input {
          display: none; /* Hide the default checkbox */
        }
        span {
          height: 15px;
          width: 15px;
          display: inline-block;
          position: relative;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 2px;
        }
        [type="checkbox"]:checked + span:before {
          content: "\2714";
          position: absolute;
          top: -2px;
          left: 1.5px;
          font-size: 14px;
        }
      }
    }
  }

  .card-pie-chart {
    display: flex;
    flex-direction: row;
    min-height: 100px;
    height: inherit;
    justify-content: space-between;
    max-height: calc(100% - 50px);
  }

  .after-divider-container {
    height: calc(100% - 110px);
  }

  .first-card {
    /* height: 35% !important; */
    height: 46% !important;
  }
  .main-container-resturants {
    .second-card {
      height: 50% !important;
    }
    .place-container {
      background: #fff;
      color: #000;
      font-size: 36px;
      line-height: 100px;
      margin: 5px;
      padding: 5%;
      position: relative;
      border-radius: 5px;
      height: 76px;
      font-family: Nunito Sans;
      font-style: normal;
      .parent {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .place-name {
        font-weight: bold;
        font-size: 14px !important;
        line-height: 19px !important;
      }

      .address {
        font-weight: 600;
        font-size: 12px !important;
        line-height: 16px !important;
      }

      .resturant-icon {
        width: 32px;
        height: 32px;
      }
    }

    .place-container-active {
      background: rgba(99, 83, 234, 1);
      color: #fff;
    }
  }

  .main-container-competitiveset {
    .pie-sales-navbtns {
      display: none;
    }
    .pie-container {
      margin-bottom: 20px;
      height: 55% !important;
      .highcharts-title {
        font-size: 15px !important;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .after-divider-container {
      height: calc(100% - 51px);
    }
  }

  @media screen and (min-width: 1600px) and (min-height: 850px) and (max-height: 1000px) {
    .main-container-competitiveset {
      .pie-container {
        height: 50% !important;
      }
    }
  }

  @media screen and (max-width: 1599px) {
    .show-below-800 {
      height: calc(100% - 270px) !important;
    }
    .show-above-800 {
      height: calc(100% - 255px) !important;
    }
    .after-divider-container {
      height: calc(100% - 50px) !important;
    }
  }

  @media screen and (min-width: 1600px) and (min-height: 851px) {
    .show-above-800 {
      height: calc(100% - 240px) !important;
    }
  }

  @media screen and (min-width: 1600px) and (max-height: 850px) {
    .show-below-800 {
      height: calc(100% - 300px) !important;
    }
  }

  @media screen and (max-height: 850px) {
    .main-container-competitiveset {
      .pie-sales-navbtns {
        display: block;
      }
      .show-below-800 {
        display: block;
      }
      .show-above-800 {
        display: none;
      }
      .pie-container {
        height: 100% !important;
      }
      .card-pie-chart {
        max-height: calc(100% - 50px);
      }
    }
  }

  @media screen and (max-height: 640px) {
    .main-container-competitiveset {
      .show-below-800 {
        height: calc(100% - 140px) !important;
      }
      .card-pie-chart {
        max-height: calc(100% - 10px);
      }

      .pie-container {
        margin-bottom: 20px;
        height: 55% !important;
        .highcharts-title {
          font-size: 10px !important;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media screen and (min-height: 851px) {
    .main-container-competitiveset {
      .show-above-800 {
        display: block;
      }
      .show-below-800 {
        display: none;
      }
    }
  }

  .main-container-competitiveset {
    .top-flop-container {
      .top-flop {
        .top-flop-item {
          @media screen and (max-height: 785px) {
            &:nth-last-child(2) {
              display: flex !important;
            }
          }
          @media screen and (max-height: 830px) {
            &:nth-last-child(1) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1921px) and (max-height: 821px) {
            &:nth-last-child(2) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1921px) and (max-height: 970px) {
            &:nth-last-child(1) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1600px) and (min-height: 850px) and (max-height: 920px) {
            &:nth-last-child(1) {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0px;
  background: transparent;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 5px;
  width: 15%;
  border-radius: 0%;
  background: #bdbdbd;
  margin-top: 0px;
  border-radius: 5px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
.carousel-container-with-scrollbar {
  padding-bottom: 30px;
  overflow: visible !important;
}
.custom-slider {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
}
.custom-slider__input {
  width: 50%;
}
.slider-image-item {
  padding: 0px 0 10px 20px;
}

.carousel-container-with-scrollbar {
  padding-bottom: 0;
}

.w-85 {
  width: 85% !important;
  overflow: hidden;
}
