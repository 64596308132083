.labo-order-modal-quantity {
    font-size: 17px !important;
    color: #873CFC !important;
    font-weight: bold !important;
    margin-top: 5px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    width: min-content !important;
  }
  
  
  .labo-order-modal-quantity-action {
    background-color: #873CFC;
    color: white;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
  }