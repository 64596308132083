@import "../../assets/scss/variables";

.my-losses {
  width: 100%;
  .losses-analytics-card {
    padding: 18px 23px;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba($color: #000000, $alpha: 0.2) !important;
    min-height: 158px;
  }

  .text-container {
    margin: 0px;
    font-size: 17px;
    color: #94a3b8 !important;
    font-weight: bold !important;
    span {
      color: "#9150F6" !important;
      font-size: 16px !important;
      font-weight: bold !important;
    }
  }
}
